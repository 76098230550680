<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    style="background: rgba(0, 0, 0, 0)"
  >
    <!-- Formulario del edit personal address -->
    <q-card class="text-left q-ma-none q-pa-lg" :style="{ 'font-family': settings.typography }">
      <CloseButton />
        <q-form @submit="editPersonalAddress" class="q-gutter-md">
            <q-item class="q-ma-sm q-pa-none">
              <q-item-section>
                <q-item-label class="text-h6">{{
                  $t("Profile.personal-address.my-address")
                }}</q-item-label>
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.address")
                }}</q-item-label>
                <q-input
                  class
                  filled
                  v-model="inputAddress.Address"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-address'),
                  ]"
                  lazy-rules
                  maxlength="50"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.postalcode")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="inputAddress.PostalCode"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t(
                        'Profile.personal-address.please-introduce-postalcode'
                      ),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.city")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="inputAddress.City"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-city'),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>

            <q-item class="q-ma-md q-pa-none">
              <q-item-section>
                <q-item-label class="q-mb-sm">{{
                  $t("Profile.personal-address.country")
                }}</q-item-label>
                <q-input
                  filled
                  v-model="inputAddress.Country"
                  :rules="[
                    (val) =>
                      (val && val.length > 0) ||
                      $t('Profile.personal-address.please-introduce-country'),
                  ]"
                  lazy-rules
                  maxlength="30"
                />
              </q-item-section>
            </q-item>
          <div class="row justify-center">
            <q-btn
              class="button-blue"
              :label="$t('General.save')"
              type="submit"
              :style="{ background: settings.color_primary }"
            >
            </q-btn>
          </div>
        </q-form>
      </q-card>

    <WaitEdit v-model="waitEditDialog" :settings="settings" />
    <SuccessEdit v-model="successEditDialog" :settings="settings" />
    <FailEdit v-model="failEditDialog" :settings="settings" />
  </q-dialog>
</template>

<script>
import { ref } from "vue";
import { defineAsyncComponent } from "vue";
import { mapActions, mapState } from "vuex";
import axios from "../../../../services/Api";
import settings from "@/mixins/settings";

const WaitEdit = defineAsyncComponent(() => import("./dialogs/WaitEdit.vue"));

const SuccessEdit = defineAsyncComponent(() =>
  import("./dialogs/SuccessEdit.vue")
);

const FailEdit = defineAsyncComponent(() => import("./dialogs/FailEdit.vue"));

const CloseButton = defineAsyncComponent(() =>
  import("../../../shared/buttons/CloseButton.vue")
);

export default {
  mixins: [settings],

  components: {
    CloseButton,
    SuccessEdit,
    FailEdit,
    WaitEdit,
  },
  props: {
    dialog: {
      default: false,
    },
  },
  data() {
    return {
      inputAddress: {
        Address: "",
        PostalCode: "",
        City: "",
        Country: "",
      },
      waitEditDialog: false,
      loading: false,
      successEditDialog: false,
      failEditDialog: false,
    };
  },
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },

    ...mapState("auth", ["user"]),
  },
  methods: {
    // Función que cierra el diálogo
    close() {
      this.$emit("update:dialog", false);
    },

    ...mapActions("settings", ["getSettings"]),

    ...mapActions({ setUser: "auth/setUser" }),

    editPersonalAddress() {
      this.waitEditDialog = true;
      setTimeout(() => {
        this.onSubmitData();
      }, 3000);
    },

    // Funcion para guardar cambios. Primero hace un put concatenando los inputs y luego se hace un get para actualizar la información de usuario
    onSubmitData() {
      axios
        .put("front-catalog/customers", {
          shipping_address:
            this.inputAddress.Address +
            ", " +
            this.inputAddress.PostalCode +
            ", " +
            this.inputAddress.City +
            ", " +
            this.inputAddress.Country,
        })
        .then(() => {
          this.loading = false;
          axios.get("front-catalog/customers").then(() => {
            this.setUser();
          });
        })
        .catch(() => {
          this.failEditDialog = true;
        })
        .finally(() => {
          this.waitEditDialog = false;
          this.successEditDialog = true;
        });
    },
  },
  setup() {
    return {
      dialog: ref(false),
      maximizedToggle: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    left: 26%;
    max-width: 560px;
    min-height: 610px;
    min-width: 466px;
    position: relative;
    top: 20px;
  }
}

.button-blue {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
}

.q-card {
  background-color: $gray;
  border-radius: 25px 25px 25px 0px;
}

.q-field--filled .q-field__control {
  height: 53px !important;
}
</style>
